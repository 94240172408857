export function throttle(fn, delay = delay || 200) {
  let lastTime;
  let timer;

  return function() {
    var args = arguments;
    // 记录当前函数触发的时间
    var nowTime = Date.now();
    if (lastTime && nowTime - lastTime < delay) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        // 记录上一次函数触发的时间
        lastTime = nowTime;
        // 修正this指向问题
        console.log(this);
        fn.apply(this, args);
      }, delay);
    } else {
      lastTime = nowTime;
      console.log(this);
      fn.apply(this, args);
    }
  };
}
